<template>
  <login ref="login" :options='options' :news="news" :system="system"></login>
</template>

<script>
import login from '@/components/Login'
import { setInitStatus, setHeaderTitle } from '@/utils/localStorage.js'
const crypto = require('crypto')

export default {
  data() {
    return {
      system: {
        name: '欢迎来到',
        title: '区块链服务系统'
      },
      // 新闻
      news: [{ title: '系统尚未初始化，请设置超级管理员账号' }],
      options: {
        // 账号栏配置,不传入则采用默认账号栏配置
        account: {
          // 账号栏label名,显示在输入框上方
          // label: '用户',
          // 账号栏左侧图标,需要formStyle为icon
          icon: 'el-icon-user',
          // 账号输入框表单校验正则,作为登录操作以及第一次点击登录后的校验依据
          placeholder: '请输入用户名',
          clearable: true,
          // 校验规则
          rules: [
            { required: true, message: '请输入用户名', trigger: 'change' },
            { min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'change' }
          ]
        },
        // 密码栏配置,不传入则采用默认密码栏设置
        password: {
          // label: '密码',
          icon: 'el-icon-lock',
          placeholder: '请输入密码',
          type: 'password',
          clearable: true
        },
        confirm: {
          // label: '密码',
          icon: 'el-icon-lock',
          placeholder: '请确认密码',
          type: 'password',
          clearable: true
        },
        button: {
          label: '初始化',
          icon: 'el-icon-lock',
          clickEvent: (form) => {
            this.handleInit(form)
          }
        }
      }
    }
  },
  components: {
    login
  },
  created() {
    this.getSystem()
  },
  methods: {
    async getSystem() {
      const { data } = await this.$api.setting.getSystem()
      // console.log(data)
      if (data.systemName) {
        this.system.title = data.systemName
        setHeaderTitle(data.systemName)
      }
    },
    // 哈希加密(SHA1)
    encrypt(password) {
      const hash = crypto.createHash('sha1')
      hash.update(password)
      return hash.digest('hex')
    },
    async handleInit(form) {
      let params = {
        loginName: form.account,
        password: this.encrypt(form.password)
      }
      this.$store.dispatch('init', params)
        .then(() => {
          setInitStatus(true)
          this.$message.success('初始化成功！')
          this.$router.push({ path: '/home' })
        })
        .catch((error) => {
          this.$message.error(error)
        })
    }
  }
}
</script>

<style lang="scss">
.ym-container {
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: auto;
  background-size: 100% 100%;
  background: url('../../../../assets/img/bg_img.png') no-repeat;
  background-color: '#FFFFFF';
  .ym-card {
    position: absolute;
    width: 100%;
    transform: translateY(-50%);
    top: 50%;
    & .el-card .el-card__body {
      padding: 0;
    }
    &-box {
      display: flex;
      &-ad {
        & img {
          margin: 0 auto;
          display: block;
          width: 100%;
        }
        background: url('../../../../assets/img/ad.png') no-repeat;
        background-color: #F9FAFA;
        background-position:bottom right;
        &__logo {
          position: absolute;
          margin: 5px 15px;
          overflow: hidden;
          user-select: none;
        }
        &__title {
          font-family: "fangsong";
          font-size: $--font-size-extra-large;
          color: $--color-primary;
          text-shadow: 0px 0px 1px $--color-primary;
        }
      }
    }
    &-form {
      padding: 40px;
    }
  }
  .center {
    margin-right: auto;
    margin-left: auto;
    float: initial;
    max-width: 970px;
  }
  // .left {
  //   margin-left: 10%;
  //   margin-right: auto;
  //   float: initial;
  //   max-width: 970px;
  // }
  // .right {
  //   margin-right: 10%;
  //   margin-left: auto;
  //   float: initial;
  //   max-width: 970px;
  // }

  // 底部备案信息
  .bottom-box {
    font-size: $--font-size-extra-small;
    color: $--color-text-regular;
    position: absolute;
    bottom: 32px;
    left: 10%;
    right: 10%;
    text-align: center;
  }
}
</style>

<template>
  <div class="ym-container">
    <div class="ym-card">
      <el-card v-if="getInitStatus === true" class="center">
        <el-row class="ym-card-box">
          <el-col :sm="12" class="ym-card-box-ad hidden-xs-only">
            <!--LOGO-->
            <div class='ym-card-box-ad__logo' :style="logoOptions.style">
              <template v-if="logoOptions.logoType=='img'">
                <img :src="logoOptions.url"
                    :alt="logoOptions.title||''">
              </template>
              <template v-if="logoOptions.logoType=='text'">
                <span class="ym-card-box-ad__title">{{logoOptions.title}}</span>
              </template>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" class="ym-card-form">
            <transition name="fade-transverse" mode="out-in">
              <template>
                <login></login>
              </template>
            </transition>
          </el-col>
        </el-row>
      </el-card>
      <el-card v-else-if="getInitStatus === false" class="center">
        <el-row class="ym-card-box">
          <el-col :sm="12" class="ym-card-box-ad hidden-xs-only">
            <!--LOGO-->
            <div class='ym-card-box-ad__logo' :style="logoOptions.style">
              <template v-if="logoOptions.logoType=='img'">
                <img :src="logoOptions.url"
                    :alt="logoOptions.title||''">
              </template>
              <template v-if="logoOptions.logoType=='text'">
                <span class="ym-card-box-ad__title">{{logoOptions.title}}</span>
              </template>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" class="ym-card-form">
            <transition name="fade-transverse" mode="out-in">
              <template>
                <init @initStatus="initStatus"></init>
              </template>
            </transition>
          </el-col>
        </el-row>
      </el-card>
    </div>

    <!-- 备案信息栏 -->
    <div class="bottom-box">
      <span></span>
    </div>
  </div>
</template>

<script>
import login from './login'
import init from './init'
import { setInitStatus } from '@/utils/localStorage.js'
import { Message } from 'element-ui'
import user from '@/service/modules/user.js'

export default {
  data() {
    return {
      // 左上角logo配置项
      logoOptions: {
        logoType: 'img',
        title: 'YIMI蚁米',
        url: require('@/assets/img/logo-header.png'),
        style: {
          width: '180px',
          left: '40px',
          top: '34px'
        }
      },
      getInitStatus: '1'
    }
  },
  components: {
    login,
    init
  },
  // beforeRouteEnter(to, from, next) {

  // },
  created() {
    user.checkInit()
      .then(res => {
        if (res.code === 200) {
          if (res.data) {
            // 已完成初始化
            console.log('已完成初始化')
            setInitStatus(true)
            this.getInitStatus = true
          } else {
            // 未完成初始化
            console.log('未初始化')
            setInitStatus(false)
            this.getInitStatus = false
            // console.log({ status: this.getInitStatus })
          }
        } else {
          Message.error(res.data.message)
        }
      })
      .catch(err => {
        // 初始化失败
        Message.error(err)
      })
  },
  methods: {
    initStatus(status) {
      this.getInitStatus = status
    }
  }
}
</script>

<style lang="scss">
.ym-container {
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: auto;
  background-size: 100% 100%;
  background-color: '#FFFFFF';
  .ym-card {
    position: absolute;
    width: 100%;
    transform: translateY(-50%);
    top: 50%;
    & .el-card .el-card__body {
      padding: 0;
    }
    &-box {
      display: flex;
      &-ad {
        & img {
          margin: 0 auto;
          display: block;
          width: 100%;
        }
        background: url('../../../../assets/img/ad.png') no-repeat;
        background-color: #F9FAFA;
        background-position:bottom right;
        &__logo {
          position: absolute;
          margin: 5px 15px;
          overflow: hidden;
          user-select: none;
        }
        &__title {
          font-family: "fangsong";
          font-size: $--font-size-extra-large;
          color: $--color-primary;
          text-shadow: 0px 0px 1px $--color-primary;
        }
      }
    }
    &-form {
      padding: 40px;
      min-height: 546px;
    }
  }
  .center {
    margin-right: auto;
    margin-left: auto;
    float: initial;
    max-width: 970px;
  }
  // .left {
  //   margin-left: 10%;
  //   margin-right: auto;
  //   float: initial;
  //   max-width: 970px;
  // }
  // .right {
  //   margin-right: 10%;
  //   margin-left: auto;
  //   float: initial;
  //   max-width: 970px;
  // }

  // 底部备案信息
  .bottom-box {
    font-size: $--font-size-extra-small;
    color: $--color-text-regular;
    position: absolute;
    bottom: 32px;
    left: 10%;
    right: 10%;
    text-align: center;
  }
}
</style>

<template>
  <login ref="login" :options='options' :news="news" :system="system"></login>
</template>

<script>
import login from '@/components/Login'
import { setHeaderTitle, getPath, activeMenu, getActiveMenu } from '@/utils/localStorage.js'
const crypto = require('crypto')

export default {
  data() {
    return {
      timer: null, // 定时器
      system: {
        name: '欢迎来到',
        title: '区块链服务系统'
      },
      // 新闻
      news: [{ title: '确权价值，链通信用。' }],
      options: {
        // 账号栏配置,不传入则采用默认账号栏配置
        account: {
          // 账号栏label名,显示在输入框上方
          // label: '用户',
          // 账号栏左侧图标,需要formStyle为icon
          icon: 'el-icon-user',
          // 账号输入框表单校验正则,作为登录操作以及第一次点击登录后的校验依据
          placeholder: '请输入用户名',
          clearable: true,
          // 校验规则
          rules: [
            { required: true, message: '请输入用户名', trigger: 'change' },
            { min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'change' }
          ]
        },
        // 密码栏配置,不传入则采用默认密码栏设置
        password: {
          // label: '密码',
          icon: 'el-icon-lock',
          placeholder: '请输入密码',
          type: 'password',
          clearable: true
        },
        button: {
          label: '登录',
          icon: 'el-icon-lock',
          clickEvent: (form) => {
            this.toLogin(form)
          }
        }
      },
      // 所有正常顺序的模块名称
      allModulars: [
        '/DataPlatform',
        '/OperationPlatform',
        '/Middleware',
        '/SystemManagement'
      ],
      // 所有正常顺序的二级菜单页面路径
      allMenu: [
        'statistics',
        'block',
        'ledger',
        'account',
        'workStatus',
        'dictionary',
        'collection',
        'server',
        'node',
        'warning',
        'deployment',
        'ledgerTask',
        'contractTask',
        'role',
        'controls',
        'user',
        'api',
        'message',
        'setting'
      ]
    }
  },
  components: {
    login
  },
  created() {
    this.getSystem()
  },
  mounted() {
    this.timer = null
  },
  methods: {
    async getSystem() {
      const { data } = await this.$api.setting.getSystem()
      // console.log(data)
      if (data.systemName) {
        this.system.title = data.systemName
        setHeaderTitle(data.systemName)
      }
    },
    // 哈希加密(SHA1)
    encrypt(password) {
      const hash = crypto.createHash('sha1')
      hash.update(password)
      return hash.digest('hex')
    },
    async toLogin(form) {
      let params = {
        loginName: form.account,
        password: this.encrypt(form.password)
      }
      this.$store.dispatch('login', params)
        .then((data) => {
          if (data.success) {
            // 增加跳转判断
            if (getPath()) {
              this.$router.push({ path: getPath() })
            } else {
              // console.log('菜单:', this.getMenu())
              this.$router.push({ path: this.getMenu() })
            }
            // this.refresh(840000)
            this.refresh(600000)
          } else {
            this.$message.error(data.msg)
            this.$refs.login.resetButton() // 恢复按钮
          }
        })
        .catch((error) => {
          this.$message.error(error)
          // this.getCaptcha()
          this.$refs.login.resetButton() // 恢复按钮
        })
    },
    // 获取拥有权限的菜单路径
    getMenu() {
      if (activeMenu('/homelayout')) {
        return '/home'
      } else {
        return this.hasModulars() + '/' + this.hasMenu()
      }
    },
    // 判断一级菜单是否存在
    hasModulars() {
      let allModulars = this.allModulars
      let menuList = JSON.parse(getActiveMenu())
      for (let index = 0; index < allModulars.length; index++) {
        if (menuList.includes(allModulars[index])) {
          return allModulars[index]
        }
      }
    },
    // 判断二级菜单是否存在
    hasMenu() {
      let allMenu = this.allMenu
      for (let index = 0; index < allMenu.length; index++) {
        if (activeMenu(allMenu[index])) {
          return allMenu[index]
        }
      }
    },
    // 实时刷新token
    refresh(time) {
      console.log(time)
      window.clearInterval(this.timer)
      this.timer = window.setInterval(res => {
        this.$store.dispatch('resetToken')
        console.log('刷新token!')
      }, time)
    }
  }
}
</script>
